import React from 'react';

import contactStyles from './contact.module.css';

import SiteMetadata from '../components/site-metadata/site-metadata';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import SponsorsFooter from '../components/sponsors-footer/sponsors-footer';

export default function Contact() {
  return (
    <div>
      <SiteMetadata
        title="Contact"
        description="To get in touch with Tom, use the form on this page or email tom@tomseldon.co.uk."
      />

      <Header activePageName="contact" />

      <div className={contactStyles.container}>
        <h1>Contact</h1>

        <p className={contactStyles.introText}>
          To get in touch with Tom, use the form below or email{' '}
          <a href="mailto:tom@tomseldon.co.uk">tom@tomseldon.co.uk</a>.
        </p>

        <form method="post" action="#" netlify-honeypot="bot-field" data-netlify="true" name="contact">
          <input type="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact" />

          <div className="form-group">
            <label for="name">Name</label>
            <input className="form-control" type="text" name="name" id="name" required />
          </div>

          <div className="form-group">
            <label for="email">Email</label>
            <input className="form-control" type="email" name="email" id="email" required />
          </div>

          <div className="form-group">
            <label for="subject">Subject</label>
            <input className="form-control" type="text" name="subject" id="subject" required />
          </div>

          <div className="form-group">
            <label for="message">Message</label>
            <textarea className="form-control" name="message" id="message" rows="5" required />
          </div>

          <button className="button" type="submit">
            Send
          </button>
        </form>
      </div>

      <SponsorsFooter />
      <Footer />
    </div>
  );
}
